import { Component } from '@angular/core';
import { CameraService } from '../camera.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-camera-perm-denied',
  templateUrl: './camera-perm-denied.component.html',
  styleUrls: ['./camera-perm-denied.component.css']
})
export class CameraPermDeniedComponent {
  requestToken!: string;
  constructor(
    private cameraPermissionService: CameraService,
    private route: ActivatedRoute,
    private router: Router
  ) {this.requestToken = this.route.snapshot.paramMap.get('requestToken')!;  }

  async requestCameraPermission() {
    const perm = await this.cameraPermissionService.requestCameraPermission();

    switch (perm) {
      case 'granted':
        console.log('Permission granted');
        this.router.navigate([this.requestToken, 'form']);
        break;
      case 'denied':
        console.log('Permission denied');
        this.router.navigate([this.requestToken, 'camera-perm-denied']);
        break;
      default:
        console.log('Permission default');
        break;
    }
  }

}
