import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import posthog from 'posthog-js'


posthog.init(
  environment.posthog.key,
  {
    api_host:environment.posthog.host
  }
)
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
