import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';
import { CameraService } from './camera.service';

export const cameraGuard: CanActivateFn = async (route, state) => {
    const cameraPermissionService = inject(CameraService);
    const router = inject(Router);

    const perm = await cameraPermissionService.checkCameraPermission();
    if (perm === 'granted' || perm === 'unknown') {
        return true;
    }
    else if (perm === 'prompt') {
        return router.createUrlTree([...state.url.substring(0, state.url.lastIndexOf('/')).split('/'), 'camera-perm-request']);
    }
    else {
        return router.createUrlTree([...state.url.substring(0, state.url.lastIndexOf('/')).split('/'), 'camera-perm-denied']);
    }
};
