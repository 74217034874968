<app-nav-bar [title]="'Camera access'"> </app-nav-bar>
<ion-router-outlet id="main-content"></ion-router-outlet>
<ion-content>
  <div class="flex justify-center p-4  h-screen bg-gray-50 bg-bottom bg-no-repeat bg-contain">
    <div  class="text-left flex  flex-col h-screen">
    <ion-text>
      <h2 class="text-3xl font-semibold text-slate-700">Camera access required</h2>
    </ion-text>

    <ion-card>
      <ion-card-content class="flex flex-col justify-center items-center py-4">
        <img src="/assets/Cameracamera.svg" alt="">
        <ion-text class="text-slate-500 text-md pt-5 text-center"> To accurately record the odometer reading for your vehicle, please grant access to your camera to capture photos of the odometer and dashboard. </ion-text>
      </ion-card-content>
    </ion-card>

    <ion-button expand="block" color="primary" class="mt-4" (click)="requestCameraPermission()">
      REQUEST ACCESS
    </ion-button>
  </div>
  </div>
</ion-content>
