import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit , OnChanges , SimpleChanges} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Vehicle } from '@clearroadlab/cam';
import { LoadingController } from '@ionic/angular';
import { forkJoin, catchError, of } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { PosthogService } from '../analytics/posthog.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent implements OnInit {

  isLoading: boolean = true;
  requestToken!: string;
  vehicle!: Vehicle;
  selectedVehicleVIN: string | null = null;
  error: string | null = null;
  tokenError : string | null = null;
  private _storage: Storage | null = null;

  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private loadingController: LoadingController,
    private router: Router,
    private storage: Storage,
    private posthogService: PosthogService,
  ) {
    this.requestToken = this.route.snapshot.paramMap.get('requestToken')!;
    this.init();

  }

  ngOnInit() {
    // Ensure this.requestToken is an uuid v4 for safety.
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (!regex.test(this.requestToken)) {
      this.error = 'request token is not an valid token';
      this.isLoading = false;
      // throw new Error('request token is not an uuid v4');
    }
    else {
      // Prove types for the response of the validity endpoint.
      enum RequestTokenValidityCode {
        Valid = 'valid',
        NotValidYet = 'notValidYet',
        Expired = 'expired',
        NotFound = 'notFound',
        AlreadyUsed = 'alreadyUsed',
      }
      type ValidResp_T = { valid: true, code: RequestTokenValidityCode.Valid, reason: string } |
      { valid: false, code: RequestTokenValidityCode.AlreadyUsed, reason: string } |
      { valid: false, code: RequestTokenValidityCode.Expired, reason: string, expiredAt: string } |
      { valid: false, code: RequestTokenValidityCode.NotValidYet, reason: string, validFrom: string } |
      { valid: false, code: RequestTokenValidityCode.NotFound, reason: string };

      // Load token validity and vehicle informations in parallel.
      forkJoin({
        validity: this
          .http.get(`/api/odometer-readings/request-tokens/${this.requestToken}/validity`),
        vehicle: this
          .http.get(`/api/odometer-readings/request-tokens/${this.requestToken}/vehicle`)
          // Prevent failing request to trigger error (ie. non 200 http resp), so we can
          // retrieve validity details who are more detailed (and catch additional
          // potential issues that may only be seen by the user later on). Indeed,
          // forkjoin would only return the first failing request otherwise.
          .pipe(
            catchError(errorResp => {
              return of(null);
            })
          )
      })
        .subscribe({
          next: resp => {
            const validityResp = resp.validity as ValidResp_T;
            const vehicleResp = resp.vehicle as Vehicle | null;
            // Handle weird edge case where the token is valid but the vehicle is not
            // found. (this should never happen, except due to unlikely async change of
            // token status between the two calls, or vehicle not existing).
            if (validityResp.valid === true && vehicleResp === null) {
              this.isLoading = false;
              this.error = `Vehicle not found for token ${this.requestToken} or token got invalid after validity check.`;
              return;
            }
            // Everything went on fine and we have a valid vehicle,
            // move forward.
            if (validityResp.valid === true && vehicleResp !== null) {
              this.isLoading = false;
              this.vehicle = vehicleResp as Vehicle;
              this.posthogService.identify(this.vehicle.vinOEM as string);
              this.selectedVehicleVIN = this.vehicle.vin;
            }
            // Token is not valid, shouldn't happen because error
            // are supposed to be handled in the error callback (40x
            // http code).
            else {
              this.isLoading = false;
              this.error = `Unexpected error. `;
            }
          },
          error: (eResp: HttpErrorResponse) => {
            const resp = eResp.error as ValidResp_T;
            // Check network issue.
            if (eResp.status === 0) {
              this.isLoading = false;
              this.error = `Unexpected network issue.`; // might both be client related or backend
              return;
            }
            // Check if the error is an invalid server-side error (should never happen).
            else if (!resp || typeof resp.valid === 'undefined') {
              this.isLoading = false;
              this.error = `Unexpected error, returned server-side error is not valid.`;
              return;
            }
            // Check if the error returns the token is valid (should never happen).
            else if (resp.valid === true) {
              this.isLoading = false;
              this.error = `Unexpected error while server-side returns valid key.`;
              return;
            }
            // Map the error to the corresponding message.
            else if (resp.valid === false) {
              switch (resp.code) {
                case RequestTokenValidityCode.AlreadyUsed:
                  this.isLoading = false;
                  this.tokenError = `The token has already been used.`;
                  return;
                case RequestTokenValidityCode.Expired:
                  this.isLoading = false;
                  this.tokenError = `The token has expired.`;
                  return;
                case RequestTokenValidityCode.NotValidYet:
                  this.isLoading = false;
                  this.error = `The token is not valid yet.`;
                  return;
                case RequestTokenValidityCode.NotFound:
                  this.isLoading = false;
                  this.error = `The token has not been found.`;
                  return;
                // Unexpected behavior (should never happen).
                default:
                  this.isLoading = false;
                  this.error = `Unexpected error, unknown validity code.`;
                  return;
              }
            }
            // Unexpected behavior (should never happen).
            else {
              this.isLoading = false;
              this.error = `Unexpected error.`;
              return;
            }
          }
        });
    }
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Please wait...',
    });
    await loading.present();
  }

  async dismissLoading() {
    await this.loadingController.dismiss();
  }

  async start(){
    await this.setObject('vehicle', this.vehicle);
    this.router.navigate([this.requestToken,'form']);
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  async setObject(key: string, object: any) {
    await this._storage?.set(key, object);
  }
}
