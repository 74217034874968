import { Injectable } from '@angular/core';
import posthog from 'posthog-js';

@Injectable({
  providedIn: 'root'
})
export class PosthogService {


  constructor(
  ) { }

  public identify(vinOEM: string) {
     posthog.identify(vinOEM);
    }

  public event(eventName: string, properties: Record<string, unknown>) {
    posthog.capture(eventName, properties);
  }
}
