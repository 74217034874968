import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CameraComponent } from './camera/camera.component';
import { CapturePageComponent } from './capture-page/capture-page.component';
import { CameraPermDeniedComponent } from './camera-perm-denied/camera-perm-denied.component';
import { CameraPermRequestComponent } from './camera-perm-request/camera-perm-request.component';
import { FormComponent } from './form/form.component';
import { IonicModule } from '@ionic/angular';
import { WarningModalComponent } from './warning-modal/warning-modal.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { FaqPageComponent } from './faq-page/faq-page.component';

@NgModule({
  declarations: [
    AppComponent,
    CameraComponent,
    CapturePageComponent,
    CameraPermDeniedComponent,
    CameraPermRequestComponent,
    FormComponent,
    WarningModalComponent,
    WelcomePageComponent,
    NavBarComponent,
    FaqPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot({}),
    IonicStorageModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
