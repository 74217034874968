<app-nav-bar [title]="'Camera access'"> </app-nav-bar>
<ion-router-outlet id="main-content"></ion-router-outlet>
<ion-content>
  <div class="flex justify-center p-4  h-screen bg-gray-50 bg-bottom bg-no-repeat bg-contain">
    <div class="flex flex-col h-screen">
      <ion-card>
        <ion-card-content class="flex flex-col text-left items-center py-4">
          <img src="/assets/denied-camera-icon.svg" alt="">
          <ion-card-title class="ion-margin-top text-slate-700 text-xl"> Permission denied </ion-card-title>
          <ion-text class="text-slate-500 text-md pt-5"> To enable camera access please follow next steps based on your
            phone's operating system. </ion-text>
          <ion-list lines="none" class="self-start w-full">
            <ion-list-header>
              <ion-label>Android:</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-label>1.Open "Settings.</ion-label>
            </ion-item>
            <ion-item>
              <ion-label>2.Go to "Apps" or "Applications."</ion-label>
            </ion-item>
            <ion-item>
              <ion-label>3.Find your browser app.</ion-label>
            </ion-item>
            <ion-item>
              <ion-label>4.Enable camera permission.</ion-label>
            </ion-item>
          </ion-list>

          <ion-list lines="none" class="self-start w-full">
            <ion-list-header>
              <ion-label>IOS:</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-label>1.Open "Settings.</ion-label>
            </ion-item>
            <ion-item>
              <ion-label>2.Scroll down and tap on your browser app.</ion-label>
            </ion-item>
            <ion-item>
              <ion-label>3.Enable camera access.</ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </div>
  </div>
</ion-content>
