import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
})
export class WarningModalComponent {
  @Input() odometer = false;
  @Input() dashboard = true;

  constructor(private modalController: ModalController) {
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true,
      action: 'restart'
    });
  }

  forceSubmit() {
    this.modalController.dismiss({
      dismissed: true,
      action: 'forceSubmit'
    });
  }
}
