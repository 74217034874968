<ion-header>
    <ion-toolbar color="primary">
        <ion-buttons slot="start">
            <ion-button>
                <ion-icon slot="icon-only" name="menu"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>Odometer Photo</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content  class="ion-padding">
    <ion-item lines="none">
        <ion-text color="dark">
            <h2 class="text-2xl font-normal">Select your vehicle</h2>
        </ion-text>
    </ion-item>
    <ion-item lines="full">
        <ion-text *ngIf="error" color="dark" class="mb-4">
            {{ error }}
        </ion-text>
        <ion-text *ngIf="!error && isLoading" color="dark" class="mb-4">
            Loading vehicle informations...
        </ion-text>
        <ion-select *ngIf="vehicle" label="Vehicle" placeholder="Select vehicle" interface="action-sheet" [(ngModel)]="selectedVehicleVIN" class="mb-4">
            <ion-select-option [value]="vehicle.vin">{{vehicle.make}} {{vehicle.vehicleModel}} {{vehicle.modelYear}}</ion-select-option>
        </ion-select>
    </ion-item>
    <router-outlet *ngIf="vehicle"></router-outlet>
</ion-content>

<!-- @warning messing up with router navingation when enabled! -->
<!-- <ion-tabs> -->
    <!-- <ion-tab-bar slot="bottom"> -->
        <!-- <ion-tab-button tab="Odometer">
            <ion-icon name="play-circle"></ion-icon>
            Odometer
        </ion-tab-button>
        <ion-tab-button tab="radio">
            <ion-icon name="radio"></ion-icon>
             Radio
        </ion-tab-button>
        <ion-tab-button tab="library">
            <ion-icon name="library"></ion-icon>
            Library
        </ion-tab-button>
        <ion-tab-button tab="search">
            <ion-icon name="search"></ion-icon>
            Search
        </ion-tab-button> -->
    <!-- </ion-tab-bar> -->
<!-- </ion-tabs> -->