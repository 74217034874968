<ion-menu side="end" contentId="main-content" #menu>
  <ion-header>
    <ion-toolbar>
      <ion-title >Menu</ion-title>
      <ion-buttons slot="end">
        <ion-menu-toggle>
          <ion-button>
            <ion-icon name="close"></ion-icon>
          </ion-button>
        </ion-menu-toggle>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list>
      <ion-menu-toggle  auto-hide="true">
        <ion-item href="https://www.utahroadusagecharge.com/" target="_blank">About</ion-item>
        <ion-item href="https://www.utahroadusagecharge.com/EN/contact-us" target="_blank">Contact</ion-item>
        <ion-item routerLink="/faq" (click)="closeMenu()">FAQs</ion-item>
      </ion-menu-toggle>
    </ion-list>
  </ion-content>
</ion-menu>

<ion-toolbar>
  <ion-button  fill="clear"  (click)="goBack()" *ngIf="showBackButton">
    <ion-icon name="arrow-back"></ion-icon>
  </ion-button>
  <ion-title>{{title}}</ion-title>
  <ion-buttons slot="end">
    <ion-menu-button auto-hide="false"></ion-menu-button>
  </ion-buttons>
</ion-toolbar>
