import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent  implements OnInit {
  @Input() title: string = 'Title';
  @Input() showBackButton: boolean = false;

  constructor(private location: Location ,  private menuController: MenuController) {
  }

  ngOnInit() { this.closeMenu()}

  goBack() {
    this.location.back();
  }

  closeMenu() {
    this.menuController.close();
  }
}
