<ion-router-outlet id="main-content"></ion-router-outlet>
<ion-content>
  <div class="bg-gray-50 bg-bottom bg-no-repeat bg-contain h-screen bg">
    <app-nav-bar [title]="'Faq'" [showBackButton]="true"> </app-nav-bar>
    <div class="flex items-center flex-col">
      <ion-accordion-group class="w-full">
        <ion-accordion value="first">
          <ion-item slot="header" color="light">
            <ion-label>How do I submit my odometer reading using the manual odometer photo option?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <p>Participants who opt for the manual odometer photo option will receive a reminder and link to submit their odometer reading every three months.</p>
            <p>With an internet and camera equipped device, the participant must use the link provided to input their odometer reading value and submit 2 photos: 1) a photo of their dashboard, and 2) a close-up photo of their odometer.</p>
            <p>The information provided will be submitted for review and verification to ensure accuracy. Once approved the odometer reading submitted will be used to determine the vehicle miles driven for the period.</p>
            <p>Additional guidance and instructions are provided in each step of the submission process.</p>
          </div>
        </ion-accordion>
        <ion-accordion value="second">
          <ion-item slot="header" color="light">
            <ion-label>What should the dashboard photo include?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <p>The dashboard photo submitted should adhere to the following guidelines:</p>
            <ul>
              <li>Must show the dashboard with odometer display and steering wheel;</li>
              <li>Must be clear and free from glare, obstruction, or blur;</li>
              <li>The dashboard with odometer display and steering wheel must be captured within the camera frame guide provided when taking the photo;</li>
            </ul>
          </div>
        </ion-accordion>
        <ion-accordion value="third">
          <ion-item slot="header" color="light">
            <ion-label>What should the closeup odometer photo include?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <p>The closeup odometer photo submitted should adhere to the following guidelines:</p>
            <ul>
              <li>Must show the vehicle's odometer display with the total vehicle odometer value and NOT trip odometer value;</li>
              <li>Must be clear and free from glare, obstruction, or blur;</li>
              <li>The vehicle's odometer display must be captured within the camera frame guide provided when taking the photo;</li>
            </ul>
          </div>
        </ion-accordion>
        <ion-accordion value="fourth">
          <ion-item slot="header" color="light">
            <ion-label>How do I make sure that my odometer reading submission is approved?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <p>To avoid delays in odometer reading submission and reduce the likelihood of odometer reading submissions being rejected, please make sure to do the following:</p>
            <ul>
              <li>Confirm that the vehicle information shown through your unique link is correct;</li>
              <li>Ensure that the odometer value you type in matches the odometer value in the photo submitted;</li>
              <li>Make sure that the photos submitted are clear and free from glare, obstruction, or blur;</li>
              <li>Follow the guidelines in taking the two types of photos needed.</li>
            </ul>
          </div>
        </ion-accordion>
        <ion-accordion value="fifth">
          <ion-item slot="header" color="light">
            <ion-label>Why can't I upload a photo of my odometer reading?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <p>Odometer reading photos can only be submitted by direct photo capture through the unique link provided to you. </p> <p>We do not allow for photo uploads to prevent potential fraudulent submissions and photo manipulation.</p>
          </div>
        </ion-accordion>
        <ion-accordion value="sixth">
          <ion-item slot="header" color="light">
            <ion-label>Why did my odometer reading submission fail?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <p>Odometer reading submissions failing mean they do not pass our checks and validation requirements and may be due to a variety of reasons including but not limited to:</p>
            <ul>
              <li>Mismatching reported odometer value typed in with the odometer value in the photos provided;</li>
              <li>The photo submitted is of a vehicle that does not match the vehicle for which the link provided is for;</li>
              <li>Poor photo quality or obstruction in photo;</li>
              <li>Potential fraudulent photo detected.</li>
            </ul>
            <p>To ensure submission approval, please make sure to follow the guidelines provided and follow the instructions carefully.</p>
          </div>
        </ion-accordion>
        <ion-accordion value="seventh">
          <ion-item slot="header" color="light">
            <ion-label>What do I do if I entered a wrong odometer value?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <p>If you entered a wrong odometer value and it does not match the odometer value in the photo submitted, your odometer reading submission may be rejected. </p> <p>Make sure that you input the correct odometer value and that the value matches that of the photo taken in your submission.</p>
          </div>
        </ion-accordion>
        <ion-accordion value="eighth">
          <ion-item slot="header" color="light">
            <ion-label>Should I round off the odometer value?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <p>If the odometer value includes decimals, always round DOWN to the nearest unit. </p> <p>  For example, if your odometer value shows 52002.79, please type in 52002 as your odometer value.</p>
          </div>
        </ion-accordion>
        <ion-accordion value="ninth">
          <ion-item slot="header" color="light">
            <ion-label>How often should I submit my odometer reading?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <p>Road Usage Charge (RUC) program participants who select the manual odometer photo option must first report their vehicle's initial odometer reading at the start of their participation in the RUC program.</p> <p>Once participating in RUC program, the participant will receive a request to report their odometer reading every three month beginning the month of their vehicle's registration renewal.</p>
          </div>
        </ion-accordion>
        <ion-accordion value="tenth">
          <ion-item slot="header" color="light">
            <ion-label>What unit should I use when adding my odometer value? Should I convert my odometer value to a different unit?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <p>Use the unit shown on your dashboard. DO NOT input a converted value. </p> <p>  Type in the odometer value as it appears on your dashboard, rounding down if there is a decimal value.</p>
          </div>
        </ion-accordion>
        <ion-accordion value="eleventh">
          <ion-item slot="header" color="light">
            <ion-label>Can I use the same link to upload the odometer reading of another vehicle? Can I use the same link in the future to upload my odometer reading?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <p>No. The links provided to submit your odometer reading are unique for each vehicle and for each odometer submission period. </p> <p>You cannot use the same link for multiple vehicles. Links will expire at the end of the reporting quarter and a new link will be provided to report mileage in the next quarter.</p>
          </div>
        </ion-accordion>
        <ion-accordion value="twelfth">
          <ion-item slot="header" color="light">
            <ion-label>What if I forget to submit my odometer reading?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <p>If you forget to submit your odometer reading, you will be charged the maximum mileage for the period.</p> <p> Participants who miss multiple odometer reading submissions may be subject to penalties and unenrollment for the program.</p>
          </div>
        </ion-accordion>
        <ion-accordion value="thirteenth">
          <ion-item slot="header" color="light">
            <ion-label>Where can I send additional questions regarding my mileage reporting?</ion-label>
          </ion-item>
          <div class="ion-padding" slot="content">
            <p>For additional information and assistance, you may contact us at <a href="mailto:info@utahroadusagecharge.com"> info@utahroadusagecharge.com</a> or call  <a href="tel:+1-888-899-6212 "> +1-888-899-6212 </a> Monday through Friday, 8AM-4PM MT</p>
          </div>
        </ion-accordion>
      </ion-accordion-group>
      <p class="text-gray-400 text-sm mt-6">Powered by</p>
      <div class="flex justify-center items-center w-1/5 pt-5">
        <img src="/assets/udot-logo.png" alt="Logo" class="mx-auto">
        <img src="/assets/clearroad-logo.svg" alt="Logo" class="mx-auto">
      </div>
    </div>
  </div>
</ion-content>
