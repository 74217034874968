import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CapturePageComponent } from './capture-page/capture-page.component';
import { cameraGuard } from './camera-guard.guard';
import { CameraPermDeniedComponent } from './camera-perm-denied/camera-perm-denied.component';
import { CameraPermRequestComponent } from './camera-perm-request/camera-perm-request.component';
import { FormComponent } from './form/form.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { FaqPageComponent } from './faq-page/faq-page.component';
const routes: Routes = [
  {
    path: 'faq',
    component: FaqPageComponent
  },
  {
    path: ':requestToken',
    component: WelcomePageComponent,
  },
  {
    path: ':requestToken/welcome',
    component: WelcomePageComponent
  },
  {
    path: ':requestToken/form',
    component: FormComponent,
    canActivate: [cameraGuard]
  },
  {
    path: ':requestToken/camera-perm-denied',
    component: CameraPermDeniedComponent
  },
  {
    path: ':requestToken/camera-perm-request',
    component: CameraPermRequestComponent,
  },
  {
    path: '**',
    component: WelcomePageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
