<!-- Step 1: manual odometer entry -->
<ion-router-outlet id="main-content"></ion-router-outlet>
<ion-content>
  <div class="bg bg-gray-50 bg-bottom bg-no-repeat bg-contain h-fit min-h-screen">
    <app-nav-bar [title]="'Odometer reading'" [showBackButton]="false"> </app-nav-bar>
    <div *ngIf="stepIndex(step)+1 < 5" class="w-full flex items-center bg-gray-100 py-4">
      <ion-icon [hidden]="stepIndex(step)===0" name="arrow-back-outline" class="text-2xl text-slate-500 ml-2"
        (click)="onBack()"></ion-icon>
      <ion-text class="pl-4 text-sm">
        Step {{stepIndex(step)+1}} of 4 - {{stepTitles[step]}}
      </ion-text>
    </div>
    <div class="flex justify-center px-4">
      <!-- Step 1: Odometer manual reading  -->
      <form class="py-4 bg-transparent" *ngIf="step == 'odometer-entry'" [formGroup]="odometerEntryForm"
        (ngSubmit)="onOdometerEntrySubmit(odometerEntryForm)">
        <ion-text>
          <h2 class="text-3xl font-semibold text-slate-700">Enter Manual Odometer Reading</h2>
        </ion-text>
        <ion-card class="w-full ml-0">
          <ion-card-content>
            <ion-text class="flex items-center mb-3">
              <ion-icon class="text-3xl text-slate-500" name="information-circle"></ion-icon>
              <span class="text-lg text-slate-500 font-semibold pl-2"> Tips </span>
            </ion-text>
            <ion-text class="text-slate-500 text-md"> Make sure to enter the total vehicle odometer value and not a trip
              odometer value.</ion-text>
            <ion-text class="flex items-center mt-6 text-md text-slate-500">
              Always enter the rounded down value  (ex. If your odometer reads 456.78, please type in 456)
            </ion-text>
          </ion-card-content>
        </ion-card>
        <ion-label class="text-slate-500 mt-4"> Total odometer value <span class="text-red-400"> * </span></ion-label>
        <input class="mt-2 w-full p-4 bg-white border border-slate-400 rounded" type="number" placeholder="123456"
          formControlName="manualEntry" id="end-user-entry-input" />
        <p *ngIf="odometerEntryForm.get('manualEntry')!.touched && (odometerEntryForm.get('manualEntry')!.hasError('required') || odometerEntryForm.get('manualEntry')!.hasError('invalidInteger'))"
          class="text-red-400 text-sm block">Please enter a valid rounded number.</p>
        <ion-button class="mt-4" color="primary" expand="block" type="submit"
          [disabled]="!odometerEntryForm.get('manualEntry')!.valid">
          NEXT
          <ion-icon class="ml-2" name="arrow-forward-outline"></ion-icon>
        </ion-button>
      </form>
      <!-- Step 2: dashboard picture capture -->
      <form class="py-4" *ngIf="step == 'dashboard-capture'" [formGroup]="dashboardPictureForm"
        (ngSubmit)="onDashboardPictureSubmit(dashboardPictureForm)">
        <ion-text>
          <h2 class="text-3xl font-semibold text-slate-700">Submit a Dashboard Photo</h2>
        </ion-text>
        <app-camera [dashboard]="true" [odometer]="false" (valueChange)="setDashboardPicture($event)"
          formControlName="camera" class="w-full" />
        <p *ngIf="dashboardPictureForm.get('camera')!.touched && dashboardPictureForm.get('camera')!.hasError('required')"
          class="text-red-400 text-center">Picture is required</p>

        <ion-button color="primary" expand="block" type="submit" *ngIf="dashboardPictureData">
          NEXT
          <ion-icon class="ml-2" name="arrow-forward-outline"></ion-icon>
        </ion-button>
      </form>
      <!-- Step 3: odometer picture capture -->
      <form class="py-4" *ngIf="step == 'odometer-capture'" [formGroup]="odometerPictureForm"
        (ngSubmit)="onOdometerPictureSubmit(odometerPictureForm)">
        <ion-text>
          <h2 class="text-3xl font-semibold text-slate-700">Submit an Odometer Photo</h2>
        </ion-text>
        <app-camera [dashboard]="false" [odometer]="true" (valueChange)="setOdometerPicture($event)"
          formControlName="camera" class="w-full" />
        <p *ngIf="odometerPictureForm.get('camera')!.touched && odometerPictureForm.get('camera')!.hasError('required')"
          class="text-red-400 text-center">Picture is required</p>

        <ion-button color="primary" expand="block" type="submit" *ngIf="odometerPictureData">
          NEXT
          <ion-icon class="ml-2" name="arrow-forward-outline"></ion-icon>
        </ion-button>
      </form>
      <!-- Step 4: confirmation -->

      <form class="w-full" *ngIf="step == 'confirmation' && !submitting && !displayEnforceSubmitButton"
        [formGroup]="confirmationForm" (ngSubmit)="onConfirmationSubmit(confirmationForm,true)">
        <h2 class="text-3xl font-semibold text-slate-700 text-left">Review your submission</h2>

        <div class="w-full bg-white rounded p-4 mt-2">
          <p class="text-sm text-slate-400"> Vehicle Model </p>
          <p class="text-lg text-slate-700"> {{vehicle.vehicleModel}} </p>
        </div>

        <div class="w-full bg-white rounded p-4 mt-2">
          <p class="text-sm text-slate-400"> Model Year </p>
          <p class="text-lg text-slate-700"> {{vehicle.modelYear}} </p>
        </div>

        <div class="w-full bg-white rounded p-4 mt-2">
          <p class="text-sm text-slate-400"> Vehicle VIN </p>
          <p class="text-lg text-slate-700"> {{vehicle.vinOEM}} </p>
        </div>

        <div class="w-full bg-white rounded p-4 mt-2">
          <p class="text-sm text-slate-400"> Odometer value </p>
          <p class="text-lg text-slate-700"> {{odometerEntryForm.get('manualEntry')?.value}} </p>
        </div>

        <div class="w-full bg-white rounded p-4 mt-2">
          <p class="text-sm text-slate-400"> Dashboard photo </p>
          <div class="overflow-hidden mt-4">
            <img class="preview" src="{{dashboardImage?.imageB64DataUrl}}" alt="">
          </div>
        </div>

        <div class="w-full bg-white rounded p-4 mt-2">
          <p class="text-sm text-slate-400"> Odometer photo </p>
          <div class="overflow-hidden mt-4">
            <img class="preview" src="{{odometerImage?.imageB64DataUrl}}" alt="">
          </div>
        </div>

        <ion-button *ngIf="!displayEnforceSubmitButton" color="primary" fill="outline" expand="block" type="button"
          (click)="onRestart()">
          START OVER
        </ion-button>
        <ion-button *ngIf="!displayEnforceSubmitButton" color="primary" expand="block" type="submit">
          SEND ODOMETER READING
        </ion-button>
        <div class="h-16"></div>

      </form>

      <!-- Submitting  -->
      <div class="h-full flex justify-center items-center" *ngIf="submitting">
        <ion-card>
          <ion-card-content class="flex items-center justify-center flex-col">
            <ion-spinner class="text-lg text-slate-700 pt-2" name="crescent"></ion-spinner>
            <ion-text class="text-lg text-slate-600 pt-2">Sending data...</ion-text>
            <ion-text class="text-sm text-slate-400 pt-2 text-center">Please wait few moments as we handle the data
              transmission and process the information you've sent.</ion-text>
          </ion-card-content>
        </ion-card>
      </div>

      <!-- Force Submit  -->
      <div class="h-full w-full flex justify-center items-center flex-col"
        *ngIf="displayEnforceSubmitButton && !submitting">
        <img src="/assets/Brake Warning.svg" alt="">
        <ion-card>
          <ion-card-content class="flex items-center justify-center flex-col">
            <ion-text class="text-3xl text-yellow-500 font-semibold">Issue detected</ion-text>
            <ion-text class="text-sm text-slate-400 pt-4 text-center">Uh-oh! Our system has detected that the odometer
              value you typed in and the odometer reading in the photo submitted do not match.</ion-text>
            <ion-text class="text-sm text-slate-400 pt-4 text-center">To avoid delays in odometer review, please make
              sure
              that the odometer value you typed in matches the vehicle’s odometer reading in the photo
              submitted.</ion-text>
            <ion-text class="text-sm text-slate-400 pt-4 text-center">Submitting an inaccurate odometer value or a poor
              quality photo may lead to processing delays and your submission being declined.</ion-text>
          </ion-card-content>
        </ion-card>
        <ion-button class="w-full mt-4" color="primary" expand="block" type="button" (click)="onRestart()">
          START OVER
        </ion-button>
        <ion-button class="w-full" fill="outline" color="primary" expand="block" type="button"
          (click)="onConfirmationSubmit(confirmationForm, true)">
          I UNDERSTAND, SUBMIT
        </ion-button>
      </div>

      <div *ngIf="step != 'success' && submitError && false"
        class="bg-red-100 p-4 rounded-md border-l-4 border-red-800 rounded-l-lg mb-4">
        <div class="text-center">
          <ion-icon name="alert-circle" class="text-red-400 text-4xl mx-auto"></ion-icon>
          <h3 class="text-lg font-semibold text-black mt-2">Warning</h3>
          <span class="text-black text-sm font-light">{{submitError}} <br>
            1 - Please retake your picture with the correct odometer reading. <br>
            2 - Please make sure you entered correctly the manual odometer value in the first step</span>
        </div>
      </div>

      <!-- Step 5: successfully submitted -->

      <div *ngIf="step == 'success'" class="h-full flex justify-top items-center flex-col pt-5 mt-6">
        <img width="100" src="/assets/review-icon.svg" alt="">
        <ion-card>
          <ion-card-content class="flex items-center justify-center flex-col">
            <ion-text class="text-2xl text-blue-900 font-semibold text-center">Submission under review</ion-text>
            <ion-text class="text-sm text-slate-400 pt-4 text-center">We are currently reviewing your odometer reading
              submission.</ion-text>
            <ion-text class="text-sm text-slate-400 pt-4 text-center">You will receive an email on the approval status
              of
              your submission and if any additional information is needed.</ion-text>
          </ion-card-content>
        </ion-card>
      </div>

    </div>
  </div>
</ion-content>
