import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { CameraComponent, CameraOutputData } from '../camera/camera.component';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { fabric } from 'fabric';
import { LoadingController, ModalController, SelectValueAccessor } from '@ionic/angular';
import { WarningModalComponent } from '../warning-modal/warning-modal.component';
import { Vehicle } from '@clearroadlab/cam';
import { Storage } from '@ionic/storage-angular';
import { CameraService } from '../camera.service';
import { PosthogService } from '../analytics/posthog.service';

declare var window: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent {
  requestToken: string | null = null;

  odometerEntryForm: FormGroup = new FormGroup({
    manualEntry: new FormControl<number | undefined>(undefined, [Validators.required, this.integerValidator]),
  });

  dashboardPictureForm: FormGroup = new FormGroup({
    camera: new FormControl<CameraOutputData | undefined>(undefined, [Validators.required])
  });

  odometerPictureForm: FormGroup = new FormGroup({
    camera: new FormControl<CameraOutputData | undefined>(undefined, [Validators.required])
  });

  confirmationForm: FormGroup = new FormGroup({
  });

  manualEntry: string = '';
  dashboardPictureData: CameraOutputData | null = null;
  odometerPictureData: CameraOutputData | null = null;
  dashboardImage: CameraOutputData | null = null;
  odometerImage: CameraOutputData | null = null;
  submitting: boolean = false;

  submitError: string | null = null;
  displayEnforceSubmitButton: boolean = false;
  step: 'odometer-entry' | 'dashboard-capture' | 'odometer-capture' | 'confirmation' | 'success' = 'odometer-entry' ;
  stepTitles: { [key in 'odometer-entry' | 'dashboard-capture' | 'odometer-capture' | 'confirmation' | 'success']: string } = {
    'odometer-entry': 'Manual Odometer Value',
    'dashboard-capture': 'Dashboard photo',
    'odometer-capture': 'Odometer photo',
    'confirmation': 'Review',
    'success': 'Success'
  };
  capturedImage$ = this.cameraService.capturedImage$;

  vehicle!: Vehicle;
  private _storage: Storage | null = null;


  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private storage: Storage,
    private cameraService: CameraService,
    private posthogService: PosthogService

  ) { this.init();}

  async ngOnInit() {
    this.requestToken = this.route.snapshot.paramMap.get('requestToken')!;
    this.vehicle =  await this.getObject('vehicle') as Vehicle;
    this.capturedImage$.subscribe((image) => {
      this.dashboardImage = image.dashboard;
      this.odometerImage = image.odometer
    });
    // @todo show error
    if (typeof this.requestToken !== 'string') {
      throw new Error("requestToken is not a string");
    }
  }

  async onOdometerEntrySubmit(form: FormGroup) {
    if (this.odometerEntryForm.get('manualEntry') === null) { throw new Error('manualEntry is not a FormControl'); }

    const manualEntryInput: FormControl = this.odometerEntryForm.get('manualEntry') as FormControl;

    // Consider the form inputs as touched so potential errors are displayed.
    manualEntryInput.markAsTouched();

    // Ensure inputs are valid.
    if (manualEntryInput.invalid || manualEntryInput.value === null) {
      throw new Error("manualEntry is not a number");
    }
    if (this.odometerEntryForm.invalid) {
      throw new Error("odometerEntryForm is invalid");
    }

    // Store the manual entry value.
    this.manualEntry = manualEntryInput.value;

    // Switch to the next step.
    this.step = 'dashboard-capture';
  }

  async onDashboardPictureSubmit(form: FormGroup) {
    if (this.dashboardPictureForm.get('camera') === null) { throw new Error('camera is not a FormControl'); }

    const cameraInput: FormControl = this.dashboardPictureForm.get('camera') as FormControl;

    // Consider the form inputs as touched so potential errors are displayed.
    cameraInput.markAsTouched();

    // Ensure inputs are valid.
    if (cameraInput.invalid || cameraInput.value === null) {
      throw new Error("dashboard picture input is invalid");
    }
    if (this.dashboardPictureForm.invalid) {
      throw new Error("dashboardPictureForm is invalid");
    }

    // Store the picture.
    this.dashboardPictureData = cameraInput.value;

    // Switch to the next step.
    this.step = 'odometer-capture';
  }

  async onOdometerPictureSubmit(form: FormGroup) {
    if (this.odometerPictureForm.get('camera') === null) { throw new Error('camera is not a FormControl'); }

    const cameraInput: FormControl = this.odometerPictureForm.get('camera') as FormControl;

    // Consider the form inputs as touched so potential errors are displayed.
    // cameraInput.markAsTouched();  @deprecated, now handled by camera component

    // Ensure inputs are valid.
    if (cameraInput.invalid || cameraInput.value === null) {
      throw new Error("odometer picture input is invalid");
    }
    if (this.odometerPictureForm.invalid) {
      throw new Error("odometerPictureForm is invalid");
    }

    // Store the picture.
    this.odometerPictureData = cameraInput.value;

    // Switch to the next step.
    this.step = 'confirmation';
  }

  async onRestart() {
    this.submitError = null;
    this.displayEnforceSubmitButton = false;
    window.location.reload();

  }

  async onConfirmationSubmit(form: FormGroup, enforceSubmit: boolean = false) {
    try {
      this.submitting = true;
      if (this.confirmationForm.invalid) {
        throw new Error("confirmationForm is invalid");
      }
      if (this.odometerPictureData === null) {
        throw new Error("odometerPictureData is null");
      }
      if (this.dashboardPictureData === null) {
        throw new Error("dashboardPictureData is null");
      }
      if (this.manualEntry === '') {
        throw new Error("manualEntry is empty");
      }

      // Add watermark to both pictures.
      async function loadImage(url: string, options: any = {}): Promise<fabric.Image> {
        return new Promise((resolve, reject) => {
          fabric.Image.fromURL(url, (img) => {
            resolve(img);
          }, options);
        });
      }
      const bg = new fabric.Rect({
        left: 0,
        top: 0,
        fill: 'white',
        width: 45,
        height: 45
      });
      const text = new fabric.Text('CR_WM', {
        left: 0,
        top: 0,
        fontSize: 8,
        fill: 'red',
        opacity: 1
      });
      // 1. odometer
      // let width = this.odometerPictureData.size.width, height = this.odometerPictureData.size.height;
      let img = await loadImage(this.odometerPictureData.imageB64DataUrl);
      let canvas = new fabric.Canvas(null, { width: img.width, height: img.height });
      // img.scaleToWidth(width);
      // img.scaleToHeight(height);
      // Add image to canvas.
      canvas.add(img);
      // Add watermark.
      canvas.add(bg);
      canvas.add(text);
      const odometerb64WithWatermark = canvas.toDataURL({ format: 'jpeg', quality: 1 });
      // 2. dashboard
      // width = this.dashboardPictureData.size.width, height = this.dashboardPictureData.size.height;
      img = await loadImage(this.dashboardPictureData.imageB64DataUrl);
      canvas = new fabric.Canvas(null, { width: img.width, height: img.height });
      // img.scaleToWidth(width);
      // img.scaleToHeight(height);
      // Add image to canvas.
      canvas.add(img);
      // Add watermark.
      canvas.add(bg);
      canvas.add(text);
      canvas.toDataURL({ format: 'jpeg', quality: 1 });
      const dashboardb64WithWatermark = canvas.toDataURL({ format: 'jpeg', quality: 1 });

      this.http
        .post(
          `/api/odometer-readings/`, {
          requestToken: this.requestToken,
          dashboardPicture: dashboardb64WithWatermark,
          odometerPicture: odometerb64WithWatermark,
          manualEntry: +this.manualEntry,
          enforceSubmit: enforceSubmit,
          // ocrEntry: { lines, blocks, confidence, text }
        }
        )
        .subscribe({
          next: async (v: any) => {
            try {
              console.info(v);

              if (enforceSubmit || v.hasBeenSubmitted === true) {
                this.submitError = null;
                this.displayEnforceSubmitButton = false;
                this.posthogService.event('odometer_submit', { sucess: true, hasBeenSubmitted: v.hasBeenSubmitted, requestToken: this.requestToken, enforce: enforceSubmit });
                this.step = 'success';
              }
              else if (v.hasBeenSubmitted === false) {
                this.submitError = 'We could not process your odometer image.';
                this.displayEnforceSubmitButton = true;
                this.posthogService.event('odometer_submit', { sucess: true, hasBeenSubmitted: v.hasBeenSubmitted, requestToken: this.requestToken, enforce: enforceSubmit });
                this.step = 'confirmation';
              }
              else {
                this.submitError = v.error.message || v.error || "An unknown error occurred.";
                this.displayEnforceSubmitButton = true;
                this.posthogService.event('odometer_submit', { sucess: true, hasBeenSubmitted: v.hasBeenSubmitted, requestToken: this.requestToken, enforce: enforceSubmit });
                this.step = 'confirmation';
              }
              this.submitting = false;
            }
            catch(e) {
              console.error(e);
              this.submitError = e instanceof Error && e.message || "An unknown error occurred.";
            }
          },
          error: async e => {
            console.error(e);
            this.submitError = e.error.error || e.error.message || e.error;
            this.submitting = false;
          }
        });
    } catch (error: any) {
      console.error(error);
      this.submitError = error.message || "An unknown error occurred.";
      this.submitting = false;
    }
  }

  // async onSubmit(form: FormGroup) {
  //     this.submitError = null;
  //     if (this.form.get('manualEntry') === null) { throw new Error('manualEntry is not a FormControl'); }
  //     if (this.form.get('camera') === null) { throw new Error('camera is not a FormControl'); }

  //     const manualEntryInput: FormControl = this.form.get('manualEntry') as FormControl;
  //     const cameraInput: FormControl = this.form.get('camera') as FormControl;

  //     // Consider the form inputs as touched so potential errors are displayed.
  //     manualEntryInput.markAsTouched();
  //     cameraInput.markAsTouched();

  //     // Ensure inputs are valid.
  //     if (manualEntryInput.invalid || manualEntryInput.value === null) {
  //         throw new Error("manualEntry is not a number");
  //     }
  //     if (cameraInput.invalid || cameraInput.value === null) {
  //         throw new Error("cameraInput is invalid");
  //     }
  //     if (this.form.invalid) {
  //         throw new Error("form is invalid");
  //     }

  //     this.http
  //         .post(
  //             `/odometer-readings/`, {
  //                 requestToken: this.requestToken,
  //                 odometerPicture: cameraInput.value.b64Image,
  //                 manualEntry: +manualEntryInput.value,
  //                 // ocrEntry: { lines, blocks, confidence, text }
  //             }
  //         )
  //         .subscribe({
  //             next: v => this.step = 'success',
  //             error: e => {
  //                 this.submitError = e.error.message || e.error
  //             }
  //         });
  // }

  setDashboardPicture(data: CameraOutputData | null) {
    console.log('dashboard', data);
    this.dashboardPictureData = data;
  }
  setOdometerPicture(data: CameraOutputData | null) {
    this.odometerPictureData = null;
    this.odometerPictureData = data;
  }

  integerValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value != null && value !== '' && !Number.isInteger(Number(value))) {
      return { invalidInteger: true };
    }
    return null;
  }

  async presentInstructionsModal() {
    const modal = await this.modalController.create({
      component: WarningModalComponent
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();
    if (data?.action === 'forceSubmit') {
      await this.onConfirmationSubmit(this.confirmationForm , true)
    } else if (data?.action === 'restart') {
      await this.onRestart();
    }
  }
  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Please wait...',
    });
    await loading.present();
  }

  async dismissLoading() {
    await this.loadingController.dismiss();
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  async getObject(key: string) {
    const object = await this._storage?.get(key);
    return object;
  }

  stepIndex( key : string){
    return Object.keys(this.stepTitles).indexOf(key);
  }

  onBack(){
    let index = this.stepIndex(this.step);
    if (index > 0){
      this.step = Object.keys(this.stepTitles)[index - 1] as 'odometer-entry' | 'dashboard-capture' | 'odometer-capture' | 'confirmation' | 'success';
    }
  }
}
